var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Alle Mededelingen")
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c(
                    "MiButton",
                    {
                      staticClass: "mx-1",
                      attrs: { color: "primary", small: "true", icon: "add" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isShowingAnnouncementDialog = true
                        }
                      }
                    },
                    [_vm._v(" Mededeling toevoegen ")]
                  )
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.announcements && _vm.announcements.length
                  ? _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "label", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs4: "" } }, [
                                  _c("span", [_vm._v("Datum")])
                                ]),
                                _c("v-flex", { attrs: { xs8: "" } }, [
                                  _c("span", [_vm._v("Omschrijving")])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.announcements, function(
                          announcement,
                          index
                        ) {
                          return _c(
                            "v-flex",
                            {
                              key: index,
                              staticClass: "list__item",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            announcement.created_at
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs7: "" } }, [
                                    _c("span", [
                                      _vm._v(_vm._s(announcement.announcement))
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs1: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "item__icon item__icon--action"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.editAnnouncement(
                                                  announcement
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "item__icon item__icon--action delete"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteAnnouncement(
                                                  announcement
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("delete")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("span", [_vm._v("Geen mededelingen")])
                        ])
                      ],
                      1
                    )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isShowingAnnouncementDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px", persistent: "" },
              model: {
                value: _vm.isShowingAnnouncementDialog,
                callback: function($$v) {
                  _vm.isShowingAnnouncementDialog = $$v
                },
                expression: "isShowingAnnouncementDialog"
              }
            },
            [
              _c(
                "div",
                { staticClass: "defaultDialog" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "dialog-close",
                                          on: {
                                            click: function($event) {
                                              _vm.isShowingAnnouncementDialog = false
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("close")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            521461247
                          )
                        },
                        [_c("span", [_vm._v("Sluiten")])]
                      ),
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-end": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c("h2", { staticClass: "dialog-title" }, [
                                    _vm._v(
                                      "Mededeling " +
                                        _vm._s(
                                          _vm.isUpdatingAnnouncement
                                            ? "aanpassen"
                                            : "toevoegen"
                                        )
                                    )
                                  ])
                                ]
                              ),
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        name: "announcement",
                                        label: "Mededeling voor experts"
                                      },
                                      model: {
                                        value: _vm.announcement,
                                        callback: function($$v) {
                                          _vm.announcement = $$v
                                        },
                                        expression: "announcement"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mt-5": "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          "justify-end": "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { shrink: "" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fadedText link mr-3",
                                                on: {
                                                  click: function($event) {
                                                    _vm.isShowingAnnouncementDialog = false
                                                  }
                                                }
                                              },
                                              [_vm._v("Annuleren")]
                                            ),
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  small: "true"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.saveOrUpdateAnnouncement(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.isUpdatingAnnouncement
                                                        ? "Mededeling updaten"
                                                        : "Mededeling versturen"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }