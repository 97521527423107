import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Announcement } from '@/models/Announcement';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<Announcements>({})
export default class Announcements extends Vue {
  public $pageTitle = 'Meldingen voor experts';

  protected announcement = '';

  protected announcements: Announcement[] = [];

  protected selectedAnnouncement: Announcement | null = null;

  protected isEditingAnnouncement = false;

  protected isUpdatingAnnouncement = false;

  protected isShowingAnnouncementDialog = false;

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.fetchAnnouncements();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Meldingen voor experts' },
        ],
      });
  }

  protected fetchAnnouncements() {
    new Announcement()
      .limit(5000)
      .all()
      .then((announcements: Announcement[]) => {
        this.announcements = announcements;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected saveOrUpdateAnnouncement() {
    if (this.isUpdatingAnnouncement) {
      this.updateAnnouncement();
      return;
    }

    this.saveAnnouncement();
  }

  protected saveAnnouncement() {
    const payload = {
      announcement: this.announcement,
    };

    new Announcement()
      .create(payload)
      .then(() => {
        this.reset();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected editAnnouncement(announcement: Announcement) {
    this.isShowingAnnouncementDialog = true;
    this.isUpdatingAnnouncement = true;

    this.selectedAnnouncement = announcement;
    this.announcement = announcement.announcement as string;
  }

  protected updateAnnouncement() {
    if (! this.selectedAnnouncement) {
      return;
    }
    const payload = {
      announcement: this.announcement,
    };

    new Announcement()
      .setAnnouncementId(this.selectedAnnouncement.uuid as string)
      .update(payload)
      .then(() => {
        this.reset();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected reset() {
    this.announcement = '';
    this.isShowingAnnouncementDialog = false;
    this.isUpdatingAnnouncement = false;
    this.initialize();
  }

  protected deleteAnnouncement(announcement: Announcement) {
    this.$store.dispatch('openDialog', this.dialogOptions);
    this.selectedAnnouncement = announcement;
  }

  protected get dialogOptions(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet u zeker dat u deze melding wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (this.selectedAnnouncement) {
              this.selectedAnnouncement
                .delete()
                .then((response: any) => {
                  this.initialize();
                })
                .catch((error: AxiosError) => {
                  ErrorHandler.network(error);
                });
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
